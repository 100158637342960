// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/salita_logo.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/logos/black_logo.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/logos/payoutpartner_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "\n.receipt-note[data-v-b6fdaeba] {\n  padding: 20px 10px;\n  border-radius: 3px;\n  background-color: #fff5d1;\n}\n.receipt-note__title[data-v-b6fdaeba] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin-bottom: 5px;\n  font-weight: bold;\n  font-size: 16px;\n}\n.receipt-note__provider-logo[data-v-b6fdaeba] {\n  display: block;\n  width: 100%;\n  max-width: 120px;\n  height: 35px;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n.receipt-note__provider-logo--salita[data-v-b6fdaeba] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.receipt-note__provider-logo--tikktalk[data-v-b6fdaeba] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.receipt-note__provider-logo--dipps[data-v-b6fdaeba] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.receipt-note__service-company[data-v-b6fdaeba] {\n  margin-bottom: 20px;\n}\n.receipt-note__note[data-v-b6fdaeba] {\n  max-width: 560px;\n  margin-bottom: 20px;\n  color: #000;\n}\n.receipt-note__note[data-v-b6fdaeba]:last-child {\n  margin-bottom: 0;\n}\n@media (max-width: 767px) {\n.receipt-note__title[data-v-b6fdaeba] {\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n}\n.receipt-note__provider-logo[data-v-b6fdaeba] {\n    margin: 20px 0;\n}\n}\n", ""]);
// Exports
module.exports = exports;
