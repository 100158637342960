<template>
  <div class="receipt-note">
    <div class="receipt-note__title">
      <span v-if="titleText"
            class="receipt-note__title-text"
            v-html="titleText"></span>
      <span v-if="provider"
            :class="[providerClass]"
            class="receipt-note__provider-logo"></span>
    </div>
    <div v-if="companyInfo.length"
         class="receipt-note__service-company">
      <p v-for="item in companyInfo"
         :key="item.title"
         class="receipt-note__service-company-info">
        <span v-if="item.title"
              class="receipt-note__service-company-field"
              v-html="item.title"></span>
        <span class="receipt-note__service-company-value"
              v-html="item.data"></span>
      </p>
    </div>
    <template v-if="notes.length">
      <p v-for="note in notes"
         :key="note"
         class="receipt-note__note"
         v-html="note"></p>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      noteConfig: {
        type: Object,
        required: true
      }
    },
    computed: {
      titleText() { return this.noteConfig.title || ''; },
      provider() { return this.noteConfig.provider || ''; },
      companyInfo() { return this.noteConfig.companyInfo || []; },
      notes() { return this.noteConfig.notes || []; },
      providerClass() {
        return `receipt-note__provider-logo--${this.provider}`;
      }
    }
  };
</script>

<style>
  .receipt-note .note-warning {
    color: #ff003f;
    font-weight: bold;
    text-transform: uppercase;
  }
</style>

<style scoped>
  .receipt-note {
    padding: 20px 10px;
    border-radius: 3px;
    background-color: #fff5d1;
  }

  .receipt-note__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 16px;
  }

  .receipt-note__provider-logo {
    display: block;
    width: 100%;
    max-width: 120px;
    height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .receipt-note__provider-logo--salita {
    background-image: url(~@assets/imgs/salita/salita_logo.svg);
  }

  .receipt-note__provider-logo--tikktalk {
    background-image: url(~@assets/imgs/logos/black_logo.svg);
  }

  .receipt-note__provider-logo--dipps {
    background-image: url(~@assets/imgs/logos/payoutpartner_logo.svg);
  }

  .receipt-note__service-company {
    margin-bottom: 20px;
  }

  .receipt-note__note {
    max-width: 560px;
    margin-bottom: 20px;
    color: #000;
  }

  .receipt-note__note:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    .receipt-note__title {
      flex-wrap: wrap;
    }

    .receipt-note__provider-logo {
      margin: 20px 0;
    }
  }
</style>
