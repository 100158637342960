<template>
  <receipt-pricing :title="title"
                   class="receipt">
    <receipt-note v-if="showReceiptNotes"
                  :note-config="receiptNoteConfig"
                  class="receipt__note-wrapper" />
    <finish-actions />
    <sk-note v-if="cancellationNoteText"
             :text="cancellationNoteText"
             class="receipt__cancel-policy" />
  </receipt-pricing>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';
  import ReceiptPricing from '@/components/assignment_components/one_assignment/interactive_info/shared_components/ReceiptPricing';
  import ReceiptNote from '@/components/assignment_components/one_assignment/interactive_info/pt_finished/ReceiptNote';
  import FinishActions from '@/components/assignment_components/one_assignment/interactive_info/pt_actions/FinishActions';
  import CancellationPolicyPrototype from '@/prototypes/CancellationPolicyPrototype';

  export default {
    components: {
      'receipt-pricing': ReceiptPricing,
      'finish-actions': FinishActions,
      'receipt-note': ReceiptNote
    },
    extends: CancellationPolicyPrototype,
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {},
        discussion: ({chatDiscussions}) => { return chatDiscussions ? chatDiscussions.discussion || {} : {}; }
      }),
      title() { return this.$gettext('Final invoicing data'); },
      payoutType() { return this.discussion.payoutType || ''; },
      userHasDippsAccount() { return ['dipps_organization', 'dipps_individual'].includes(this.payoutType); },
      showReceiptNotes() { return this.userIsInterpreter && this.payoutType; },
      receiptNoteConfig() { return this.userHasDippsAccount ? this.dippsNotes : this.payoutIndividualNotes; },
      providerNoteText() {
        const template = this.$gettext('%{provider} will report you income to the tax office, and will withhold tax as instructed by them. You will receive your payout once per month.');
        return this.$gettextInterpolate(template, {provider: this.getProviderInfo('platformName')});
      },
      dippsNoteText() {
        const template = this.$gettext('%{provider} will send this information to your PayoutPartner account automatically, and it will be included in your next payout. You do not have to do anything to receive payment.');
        return this.$gettextInterpolate(template, {provider: this.getProviderInfo('platformName')});
      },
      dippsLoginText() {
        return this.$gettext('<a href="https://payoutpartner.com/" class="sk-link--gray-bg" target="_blank">Log into PayoutPartner to view invoice</a>');
      },
      jobProvider() { return this.assignmentObj.provider === 'skiwo' ? 'tikktalk' : this.assignmentObj.provider || ''; },
      payoutIndividualNotes() {
        return {
          title: this.getNoteTitleText(this.getProviderInfo('platformName')),
          provider: this.jobProvider,
          notes: [this.providerNoteText],
        };
      },
      dippsNotes() {
        return {
          title: this.getNoteTitleText('PayoutPartner'),
          provider: 'payoutpartner',
          notes: [this.dippsNoteText, this.dippsLoginText]
        };
      }
    },
    methods: {
      getNoteTitleText(provider) {
        const template = this.$gettext('Thank you for choosing %{provider}!');
        return this.$gettextInterpolate(template, {provider});
      },
      getProviderInfo(fieldName) {
        return this.$getProviderInfo(this.jobProvider, 'domainData', fieldName);
      }
    }
  };
</script>

<style scoped>
  .receipt {
    width: 100%;
    background-color: #fff;
  }

  .receipt__note-wrapper {
    padding: 10px;
  }

  .receipt__cancel-policy {
    margin-top: 10px;
  }
</style>
